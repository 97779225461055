import { useEffect, useRef } from "react";
import QRCodeStyling from "qr-code-styling";

const Qrcode = () => {
  const elementRef = useRef(null);

  const qrCode = new QRCodeStyling({
    width: 250,
    height: 250,
    data: window.location.href,
    dotsOptions: {
      color: "#20acad",
      type: "extra-rounded",
    },
    cornersSquareOptions: {
      type: "extra-rounded",
      color: "#278a75",
    },
    imageOptions: {
      hideBackgroundDots: true,
      imageSize: 0.4,
      margin: 0,
    },
    backgroundOptions: {
      color: "#ffffff",
    },
  });

  useEffect(() => {
    qrCode.append(elementRef.current);
  });

  return (
    <div
      data-testid="qrcode-container"
      className="flex items-center justify-center "
      ref={elementRef}
    />
  );
};

export default Qrcode;
