import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import CardPage from "./components/CardPage";
import HomePage from "./components/HomePage";
import ReactGA from "react-ga4";
ReactGA.initialize(process.env.REACT_APP_GA_CARDS_CODE, {
  debug: process.env.REACT_APP_DEV_MODE === "true",
});
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route path="/:orgName/:personFullName" element={<CardPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
